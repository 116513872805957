import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import IframeForm from "../components/IframeForm/IframeForm"

const TalentSignUp = () => (
  <Layout>
    <SEO 
      title="Talent Sign up" 
      keywords="translation, localisation, localization, English to Tagalog, Tagalog to English, cultural translation, cultural naturalization, cultural naturalisation, production management, voice acting, voice actors, voice actor management, dubbing, voice over, Australia, Philippines, Sleigh Platform, subtitles, subtitling, Synchresis, language services"
    />
        <IframeForm height="150vh" iframe='<iframe src="https://www.cognitoforms.com/f/t9o-8CFsP0qOlWtp0D9pyw?id=29" style="position:relative;width:1px;min-width:100%;*width:100%;height:100%" frameborder="0" scrolling="yes" seamless="seamless" height="556" width="100%"></iframe>'/>
  </Layout>
)

export default TalentSignUp
